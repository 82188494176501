@import (reference) './global.less';

@hXAlign: {
    left: flex-start;
    center: center;
    right: flex-end;
    between: space-between;
    around: space-around;
    evenly: space-evenly;
    stretch: stretch;
};

@hYAlign: {
    top: flex-start;
    middle: center;
    bottom: flex-end;
    stretch: stretch;
    baseline: baseline;
};

@hWrap: {
    wrap: normal;
    wrap-top: flex-start;
    wrap-middle: center;
    wrap-bottom: flex-end;
    wrap-stretch: stretch;
    wrap-between: space-between;
    wrap-around: space-around;
    wrap-evenly: space-evenly;
};

@vXAlign: {
    left: flex-start;
    center: center;
    right: flex-end;
    stretch: stretch;
};

@vYAlign: {
    top: flex-start;
    middle: center;
    bottom: flex-end;
    between: space-between;
    around: space-around;
    evenly: space-evenly;
    stretch: stretch;
    baseline: baseline;
};

@vWrap: {
    wrap: normal;
    wrap-left: flex-start;
    wrap-center: center;
    wrap-right: flex-end;
    wrap-stretch: stretch;
    wrap-between: space-between;
    wrap-around: space-around;
    wrap-evenly: space-evenly;
};

@gridSize: 4;

.extra(@prefix: ~'') {
    .@{prefix}hidden {
        display: none;
    }

    .@{prefix}invisible {
        visibility: hidden;
    }
}

.spacing(@prefix: ~'') {
    each(range(30), {
        @gap: @gridSize * @value;
        &.@{prefix}g@{gap} {
            gap: @gap * 1px;

            &:empty {
                gap: unset;
            }
        }

        &.@{prefix}gx@{gap} {
            column-gap: @gap * 1px;

            &:empty {
                column-gap: unset;
            }
        }

        &.@{prefix}gy@{gap} {
            row-gap: @gap * 1px;

            &:empty {
                row-gap: unset;
            }
        }
    });

    each(range(0, 30), {
        @padding: @value * @gridSize;
        &.@{prefix}p@{padding} {
            padding: ~"@{padding}px";
        }

        &.@{prefix}px@{padding} {
            padding-left: ~"@{padding}px";
            padding-right: ~"@{padding}px";
        }

        &.@{prefix}py@{padding} {
            padding-top: ~"@{padding}px";
            padding-bottom: ~"@{padding}px";
        }

        &.@{prefix}pt@{padding} {
            padding-top: ~"@{padding}px";
        }

        &.@{prefix}pl@{padding} {
            padding-left: ~"@{padding}px";
        }

        &.@{prefix}pb@{padding} {
            padding-bottom: ~"@{padding}px";
        }

        &.@{prefix}pr@{padding} {
            padding-right: ~"@{padding}px";
        }
    });

    each(range(0, 30), {
        @margin: @value * @gridSize;
        &.@{prefix}m@{margin} {
            margin: ~"@{margin}px";
        }

        &.@{prefix}mx@{margin} {
            margin-left: ~"@{margin}px";
            margin-right: ~"@{margin}px";
        }

        &.@{prefix}my@{margin} {
            margin-top: ~"@{margin}px";
            margin-bottom: ~"@{margin}px";
        }

        &.@{prefix}mt@{margin} {
            margin-top: ~"@{margin}px";
        }

        &.@{prefix}ml@{margin} {
            margin-left: ~"@{margin}px";
        }

        &.@{prefix}mb@{margin} {
            margin-bottom: ~"@{margin}px";
        }

        &.@{prefix}mr@{margin} {
            margin-right: ~"@{margin}px";
        }
    });
}

.horizontal-layout(@prefix: ~'') {
    .@{prefix}horizontal {
        display: flex;
        flex-direction: row;
        overflow: visible;

        & > * {
            flex: 0 0 auto;
        }
    }

    each(@hXAlign, .(@valueX, @keyX, @ix) {
        each(@hYAlign, .(@valueY, @keyY, @iy) {
            @name: if(
                @prefix = ~'',
                ~'.horizontal.@{keyX}-@{keyY}',
                ~'.@{prefix}horizontal.@{keyX}-@{keyY}, .horizontal.@{prefix}@{keyX}-@{keyY}, .@{prefix}horizontal.@{prefix}@{keyX}-@{keyY}'
            );

            @{name} {
                align-items: @valueY;
                justify-content: @valueX;
            }
        });
    });

    each(@hYAlign, {
        @name: if(
            @prefix = ~'',
            ~'.horizontal.stretch-@{key} > *',
            ~'.@{prefix}horizontal.stretch-@{key} > *, .horizontal.@{prefix}stretch-@{key} > *, .@{prefix}horizontal.@{prefix}stretch-@{key} > *'
        );

        @{name} {
            flex: 1 0 auto;
        }
    });

    each(@hWrap, {
        @name: if(
            @prefix = ~'',
            ~'.horizontal.@{key}',
            ~'.@{prefix}horizontal.@{key}, .horizontal.@{prefix}@{key}, .@{prefix}horizontal.@{prefix}@{key}'
        );

        @{name} {
            flex-wrap: wrap;
            align-content: @value;
            min-width: 0;
        }
    });

    @wrapName: if(
        @prefix = ~'',
        ~'.horizontal.no-wrap',
        ~'.@{prefix}horizontal.no-wrap, .horizontal.@{prefix}no-wrap, .@{prefix}horizontal.@{prefix}no-wrap,'
    );

    @{wrapName} {
        flex-wrap: nowrap;
    }

    @flexName: if(
        @prefix = ~'',
        ~'.horizontal > .flex',
        ~'.@{prefix}horizontal > .flex, .horizontal > .@{prefix}flex, .@{prefix}horizontal > .@{prefix}flex'
    );

    @{flexName} {
        flex-grow: 1;
        flex-basis: 0.0001px;
    }

    @shrinkName: if(
        @prefix = ~'',
        ~'.horizontal > .shrink',
        ~'.@{prefix}horizontal > .shrink, .horizontal > .@{prefix}shrink, .@{prefix}horizontal > .@{prefix}shrink'
    );

    @{shrinkName} {
        flex-shrink: 1;
    }

    each(range(5), {
        @name: if(
            @prefix = ~'',
            ~'.horizontal > .flex-@{value}',
            ~'.@{prefix}horizontal > .flex-@{value}, .horizontal > .@{prefix}flex-@{value}, .@{prefix}horizontal > .@{prefix}flex-@{value}'
        );
        @{name} {
            flex-grow: @value * 10;
            flex-basis: 0.0001px;
        }
    });
}

.vertical-layout(@prefix: ~'') {
    .@{prefix}vertical {
        display: flex;
        flex-direction: column;
        overflow: visible;

        & > * {
            flex: 0 0 auto;
        }
    }

    each(@vXAlign, .(@valueX, @keyX, @ix) {
        each(@vYAlign, .(@valueY, @keyY, @iy) {
            @name: if(
                @prefix = ~'',
                ~'.vertical.@{keyX}-@{keyY}',
                ~'.@{prefix}vertical.@{keyX}-@{keyY}, .vertical.@{prefix}@{keyX}-@{keyY}, .@{prefix}vertical.@{prefix}@{keyX}-@{keyY}'
            );

            @{name} {
                align-items: @valueX;
                justify-content: @valueY;
            }
        });
    });

    each(@vXAlign, {
        @name: if(
            @prefix = ~'',
            ~'.vertical.@{key}-stretch > *',
            ~'.@{prefix}vertical.@{key}-stretch > *, .vertical.@{prefix}@{key}-stretch > *, .@{prefix}vertical.@{prefix}@{key}-stretch > *'
        );

        @{name} {
            flex: 1 0 auto;
        }
    });

    each(@vWrap ,{
        @name: if(
            @prefix = ~'',
            ~'.vertical.@{key}',
            ~'.@{prefix}vertical.@{key}, .vertical.@{prefix}@{key}, .@{prefix}vertical.@{prefix}@{key}'
        );

        @{name} {
            flex-wrap: wrap;
            align-content: @value;
            min-height: 0;
        }
    });

    @wrapName: if(
        @prefix = ~'',
        ~'.vertical.no-wrap',
        ~'.@{prefix}vertical.no-wrap, .vertical.@{prefix}no-wrap, .@{prefix}vertical.@{prefix}no-wrap'
    );

    @{wrapName} {
        flex-wrap: nowrap;
    }

    @flexName: if(
        @prefix = ~'',
        ~'.vertical > .flex',
        ~'.@{prefix}vertical > .flex, .vertical > .@{prefix}flex, .@{prefix}vertical > .@{prefix}flex'
    );

    @{flexName} {
        flex-grow: 1;
        flex-basis: 0.0001px;
    }

    @shrinkName: if(
        @prefix = ~'',
        ~'.vertical > .shrink',
        ~'.@{prefix}vertical > .shrink, .vertical > .@{prefix}shrink, .@{prefix}vertical > .@{prefix}shrink'
    );

    @{shrinkName} {
        flex-shrink: 1;
    }

    each(range(5), {
        @name: if(
            @prefix = ~'',
            ~'.vertical > .flex-@{value}',
            ~'.@{prefix}vertical > .flex-@{value}, .vertical > .@{prefix}flex-@{value}, .@{prefix}vertical > .@{prefix}flex-@{value}'
        );
        @{name} {
            flex-grow: @value * 10;
            flex-basis: 0.0001px;
        }
    });
}

.horizontal-self(@prefix: ~'') {
    each(@hYAlign, {
        .@{prefix}self-@{key} {
            align-self: @value;
        }
    });

    .@{prefix}pull-right {
        margin-left: auto;
    }

    .@{prefix}pull-left {
        margin-right: auto;
    }
}

.vertical-self(@prefix: ~'') {
    each(@vXAlign, {
        .@{prefix}self-@{key} {
            align-self: @value;
        }
    });

    .@{prefix}pull-top {
        margin-bottom: auto;
    }

    .@{prefix}pull-bottom {
        margin-top: auto;
    }
}

.horizontal-self();
.horizontal-layout();
.vertical-self();
.vertical-layout();
.spacing();
.extra();

@media all and (max-width: @mobile-max) {
    .horizontal-self(~'m-');
    .horizontal-layout(~'m-');
    .vertical-self(~'m-');
    .vertical-layout(~'m-');
    .spacing(~'m-');
    .extra(~'m-');
}

@media all and (min-width: @mobile-max) and (max-width: @tablet-max) {
    .horizontal-self(~'t-');
    .horizontal-layout(~'t-');
    .vertical-self(~'t-');
    .vertical-layout(~'t-');
    .spacing(~'t-');
    .extra(~'t-');
}

@media all and (min-width: @tablet-max) {
    .horizontal-self(~'d-');
    .horizontal-layout(~'d-');
    .vertical-layout(~'d-');
    .vertical-self(~'d-');
    .spacing(~'d-');
    .extra(~'d-');
}

@media all and (max-width: @tablet-max) {
    .horizontal-self(~'mt-');
    .horizontal-layout(~'mt-');
    .vertical-layout(~'mt-');
    .vertical-self(~'mt-');
    .spacing(~'mt-');
    .extra(~'mt-');
}

@media all and (min-width: @mobile-max) {
    .horizontal-self(~'td-');
    .horizontal-layout(~'td-');
    .vertical-layout(~'td-');
    .vertical-self(~'td-');
    .spacing(~'td-');
    .extra(~'td-');
}
