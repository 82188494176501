@import (reference) '../style/global.less';

.backButton {
    color: @alude-storm-6;
    &:hover,
    &:focus {
        color: @alude-storm-5;
    }
    &:active {
        color: @alude-storm-7;
    }
}
