@import (reference) '../../style/global.less';

.label {
    border-radius: 16px;
    color: @alude-gray-6;
    align-self: flex-start;
    padding: 3px 12px;
    font-size: 0.8em;
    font-weight: 500;
    background-color: @alude-gray-3;
}
