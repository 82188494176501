.aldModal {
    composes: ald-modal from global;
}

.modalTitleIcon {
    margin-left: 8px;
}

@media all and (max-width: 599.5px) {
    .modalTitle {
        flex: 1;
        text-align: center;
    }

    .modalTitleIcon {
        position: absolute;
        margin: 0;
    }
}
