@import (reference) '../../../../components/style/global.less';

.logotype {
    height: 18px;
}

.drawerHeader {
    background-color: @alude-minsk-9;
    min-height: 60px;
}

.closeIcon > span {
    color: @alude-gray-0;
    top: 8px;
    left: 272px;
    font-size: 24px;
    position: fixed;
}
