@import (reference) '../style/global.less';

.aldCarousel {
    composes: ald-carousel from global;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    right: 10px;
}

.container {
    position: relative;
}

.picture {
    background-color: @alude-gray-4;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}
