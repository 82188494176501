@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/collapse/style/index.less';
@import (reference) '../../style/ant-overrides.less';

@ant-prefix: ~'lp';

.lp-collapse {
    .lp-collapse();
    border-radius: 0;
    font-size: inherit;

    &-icon-position-right > &-item > &-header {
        padding: 0 1em 0 0;
    }

    &-borderless > &-item > &-content > &-content-box {
        padding-top: 24px;
        padding-bottom: 8px;
    }

    &-content > &-content-box {
        padding: 0;
    }
    &-icon-position-right > &-item > &-header &-arrow {
        right: 0px;
    }

    & > &-item > &-header &-arrow {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    & > &-item > &-header {
        color: inherit;
    }
}
