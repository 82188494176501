@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/dropdown/style/index.less';
@import (reference) 'antd/lib/button/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-dropdown {
    .alude-dropdown();
}
