@import (reference) '../../../components/style/global.less';

.mainContainer {
    margin: 0 auto;
    max-width: 1350px;
}

.mainContainer h1 {
    font-weight: bold;
}

.postsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
}

@media (max-width: @tablet-max) {
    .postsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: @mobile-max) {
    .postsGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.container {
    width: 100%;
    max-width: 1500px;
    margin: 32px auto 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.post {
    width: 100%;
    max-width: 1300px;
    display: flex;
    gap: 64px;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
}

.post iframe {
    border: 0;
    overflow: hidden;
}

.aludeNewsSidebar {
    width: 300px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 32px;
    gap: 16px;
    position: sticky;
    top: calc(64px + 32px);
    margin-bottom: 64px;
}
