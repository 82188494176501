@import (reference) '../../style/global.less';

.banner {
    text-align: center;
    background-color: @alude-gray-2;
}

.banner .content {
    width: 100%;
    max-width: 800px;
}

.banner p {
    font-size: 16px;
}

.banner p.after {
    font-size: 14px;
}

.banner.red {
    background-color: @alude-red-2;
}

.banner.green {
    background-color: @alude-green-2;
}

.banner.blue {
    background-color: @alude-blue-2;
}

.banner.minsk {
    background-color: @alude-minsk-1;
}

.banner.orange {
    background-color: @alude-orange-2;
}
