@import (reference) '../../../components/style/global.less';

.mainContainer {
    margin: 0 auto;
    max-width: 1350px;
}

.banner {
    max-height: 400px;
    & img {
        object-fit: cover;
        max-height: 400px;
    }
}

.container {
    max-width: 900px;
    margin: 0 auto;
    font-size: 18px;
}

.mainContainer h1 {
    font-weight: bold;
    font-size: clamp(32px, 2.22vw, 40px);
    line-height: 120%;
}

.blogGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
}

@media (max-width: @tablet-max) {
    .blogGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: @mobile-max) {
    .blogGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.containerWithSidebar {
    max-width: calc(900px + 300px + 32px);
}

@media (max-width: @tablet-max) {
    .containerWithSidebar {
        max-width: 900px;
    }
}

.backButton {
    width: 100%;
    text-align: left;
}

.sidebarCta {
    border-top: 2px solid @alude-gray-4;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    margin-top: 16px;
}

.search {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
}

.search > *:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
}
.search > *:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
}

@media (max-width: @mobile-max) {
    .search {
        gap: 24px;
    }
}

.categoriesDropdown {
    width: 100%;
    text-align: left;
    justify-content: space-between;
}
