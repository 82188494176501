@import (reference) '../style/global.less';

.dropdown {
    composes: alude-dropdown from global;
}

.overlay {
    &:extend(.elevation-element);
    border-radius: @alude-border-radius;
    background: white;
}

.iconArea {
    font-size: 0;
    margin-left: 8px;

    svg {
        font-size: 12px;
    }
}
