.button {
    composes: ald-btn from global;
}

.secondaryButton {
    composes: ald-btn-secondary from global;
}

.primeButton {
    composes: ald-btn-prime from global;
}

/*
    TODO: Chromatic is broken for empty files. The CSS file generated from this
    CSS module is empty after Storybook's build. The following selector is a
    workaround to make the file not empty. I reported the bug and we can remove
    the selector when they fix the problem.
*/
#dummySelector {
    background-color: #fff;
}
