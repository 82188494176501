@import (reference) '../../style/global.less';

.banner {
    background-color: @alude-gray-2;
    border-radius: 16px;
    padding: 32px 36px;
    margin: 30px 0px;
}

.banner h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 32px;
}

.banner p {
    margin: 0;
    padding: 0;
}

.banner.red {
    background-color: @alude-red-2;
}

.banner.green {
    background-color: @alude-green-2;
}

.banner.blue {
    background-color: @alude-blue-2;
}

.banner.minsk {
    background-color: @alude-minsk-1;
}

.banner.orange {
    background-color: @alude-orange-2;
}

.banner > div:nth-child(1) {
    flex: 1 0 0;
    display: flex;
    align-items: flex-start;
}

.banner > div:nth-child(2) {
    width: 200px;
}

.banner > div:nth-child(2) img {
    width: 200px;
}
