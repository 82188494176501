.animatedPath {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    animation: dash 3s ease-in-out alternate infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1;
    }
    40% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
