@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/drawer/style/index.less';
@import (reference) '../style/ant-overrides.less';

@drawer-prefix-cls: ~'ald-drawer';

.@{drawer-prefix-cls} {
    &-header,
    &-header-no-title {
        background: @drawer-bg;
        align-items: center;
        border-bottom: 1px solid @alude-gray-4;
        color: @alude-minsk-10;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        min-height: 60px;
    }

    &-header-no-title {
        padding: @padding-md;
    }

    &-content-wrapper {
        max-width: 100%;
    }

    &-content {
        background: @alude-gray-1;
    }

    &-footer {
        background: @drawer-bg;
        padding: 0;
        border-top: 1px solid @alude-gray-4;
    }

    &-close {
        display: inline-block;
        left: 0;
        right: auto;
        padding: 0;
        position: relative;
        margin-right: 16px;

        & .@{iconfont-css-prefix} {
            font-size: 20px;
        }
    }

    &-title {
        font-size: 20px;
        font-weight: normal;
    }

    &:extend(.elevation-element);
}

.ald-drawer {
    .ald-drawer();
}
