@import (reference) '../../style/global.less';

.container {
    width: 100vw !important;
}

.carousel {
    display: flex;
    justify-content: center;
}

.child {
    display: flex !important;
    justify-content: center;
    padding: 0 32px;

    & > * {
        max-width: @lp-section-max-width;
    }
}

@media all and (max-width: @tablet-max) {
    .child {
        padding: 0 16px;
    }
}

.buttons {
    width: min(100%, @lp-section-max-width);
}
