@import (reference) '../../../components/style/global.less';

.posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
}

@media (max-width: @tablet-max) {
    .posts {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: @mobile-max) {
    .posts {
        grid-template-columns: repeat(1, 1fr);
    }
}
