@import (reference) '../style/global.less';

.default {
    border-radius: @alude-border-radius;
    box-sizing: border-box;
    background: @alude-gray-0;
    transition: 0.3s;
    position: relative;

    // fixes changes in size when altering elevation
    border: 1px solid transparent;
}

.loading {
    cursor: default;
    opacity: 0.4;
}

.link {
    color: unset;

    &:hover {
        color: unset;
    }
}

.spinner {
    animation-duration: 1s;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    position: absolute;
    font-size: 24px;
}

.flat,
.clickable,
.floating,
.flat-shadow {
    composes: default;
}

.flat {
    border: 1px solid @alude-gray-4;
}

.clickable {
    &:extend(.clickable-element);
    cursor: pointer;

    &:hover {
        &:extend(.elevation-element);
    }

    &:focus,
    &:active {
        box-shadow: none;
        background-color: @alude-gray-2;
    }
    border: 1px solid @alude-gray-4;
}

.floating {
    &:extend(.elevation-element);
    border-radius: @alude-larger-border-radius;
}

.flat-shadow {
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.borderInBetween > div:not(:last-of-type) {
    border-bottom: @alude-card-border;
}
