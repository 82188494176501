@import (reference) '../../style/global.less';

.container {
    width: 100%;
    height: 100%;
    max-width: 1504px;

    & > div {
        height: 100%;
    }
}

.headerItem {
    height: 100%;
    &:hover {
        border-top: solid 4px @alude-red-orange;
        margin-bottom: 4px;
    }

    & > a,
    & > .item {
        height: 100%;
        text-align: center;
    }
}

.arrow {
    font-size: 8px;
}

.items {
    height: 100%;
    & > * {
        padding: 0 16px;
    }
}

.subHeader {
    position: absolute;
    top: 64px;
    left: 0px;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    background: white;

    & > div {
        width: calc(100% - 64px);
        max-width: 1440px;
    }
}

.subItems {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 40px;
    row-gap: 40px;

    & > a {
        grid-column: span 3 / auto;
    }

    &:hover > * {
        opacity: 0.5;
    }
}

.subItem {
    & svg {
        color: @alude-red-orange;
    }

    & p {
        margin: 0;
    }

    &:hover {
        opacity: 1;
    }
}

.overlay {
    position: absolute;
    inset: 64px 0px 0px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}
