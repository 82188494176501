@media print {
    * {
        print-color-adjust: exact !important;
        overflow: visible;
    }

    @page {
        margin: 0;
    }

    body {
        padding: 1cm;
    }
}
