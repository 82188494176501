@import (reference) '../../style/global.less';

.category {
    border-radius: 16px;
    color: @alude-gray-0;
    align-self: flex-start;
    padding: 2px 12px;
    font-size: 0.8em;
    font-weight: 500;
    background-color: @alude-gray-6;
}

.category.blue {
    background-color: @alude-blue-base;
}
.category.gray {
    background-color: @alude-gray-6;
}
.category.green {
    background-color: @alude-green-base;
}
.category.minsk {
    background-color: @alude-minsk-base;
}
.category.orange {
    background-color: @alude-orange-base;
}
.category.red {
    background-color: @alude-red-base;
}
.category.storm {
    background-color: @alude-storm-base;
}
