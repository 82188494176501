@import (reference) '../../../components/style/global.less';

.orb {
    background-repeat: no-repeat;
    background-position: top -200px right -200px;
    background-size: 920px 840px;

    &.red {
        background-color: @alude-red-1;
        background-image: radial-gradient(
            circle,
            @alude-red-3 5%,
            rgba(255, 255, 255, 0) 60%
        );
    }

    &.yellow {
        background-color: @gold-1;
        background-image: radial-gradient(
            circle,
            @gold-3 5%,
            rgba(255, 255, 255, 0) 60%
        );
    }

    &.blue {
        background-color: @alude-blue-1;
        background-image: radial-gradient(
            circle,
            @alude-blue-3 5%,
            rgba(255, 255, 255, 0) 60%
        );
    }
}
