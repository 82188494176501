@import (reference) '../../../components/style/global.less';

.chatButton {
    width: 100%;
    height: 100%;
    background-color: @alude-blue-6;
}

.chatContainer {
    width: 64px;
    height: 64px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
}

.chatIcon {
    width: 24px;
    height: 24px;
}
