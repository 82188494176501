@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/menu/style/index.less';
@import (reference) 'antd/lib/menu/style/status.less';
@import (reference) '../style/ant-overrides.less';

.@{menu-prefix-cls} {
    &:extend(.semibold-alude);

    .@{menu-prefix-cls}-submenu-title:hover:not(
            .@{menu-prefix-cls}-submenu-selected div
        ) {
        color: @menu-item-color;
    }

    .@{menu-prefix-cls}-item:hover:not(.@{menu-prefix-cls}-item-selected) {
        color: @menu-item-color;

        // for react-router-dom's navlinks
        a {
            color: @menu-item-color;
        }
    }

    &-inline,
    &-vertical,
    &-vertical-left,
    &-vertical-right {
        border-right: none;

        .@{menu-prefix-cls}-submenu-inline {
            width: 100%;
        }

        .@{menu-prefix-cls}-submenu-title {
            padding-left: 20px !important;
        }

        .@{menu-prefix-cls}-item {
            &:not(.@{menu-prefix-cls}-item-only-child) {
                padding-left: 20px !important;
            }

            &::after {
                right: auto;
                left: 0;
            }
        }
    }

    &-item-disabled,
    &-submenu-disabled {
        // Ant uses !important in this rule as well
        color: @alude-gray-5 !important;
    }
}
