@import (reference) 'MenuMixin.less';
@import (reference) '../style/global.less';

@menu-bg: @alude-minsk-9;
@menu-inline-submenu-bg: @alude-minsk-9;
@menu-popup-bg: @alude-minsk-9;

@menu-prefix-cls: ~'alude-lateral-menu';

.alude-lateral-menu {
    .@{menu-prefix-cls}-item:hover,
    .@{menu-prefix-cls}-submenu-title:hover {
        background-color: @menu-item-active-bg;
    }

    .alude-lateral-menu();

    .alude-lateral-menu-sub {
        font-weight: normal;
    }
}
