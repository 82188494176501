@import (reference) '../../../components/style/global.less';

.googleButton {
    justify-content: center;
    border-radius: 4px !important;
    align-items: center;
    height: 40px;
    box-shadow: @alude-box-shadow !important;
    transition: opacity @ant-transition-duration @ant-transition-easing;

    & > div {
        height: 34px;
        margin-left: 2px;
    }
    & svg {
        margin-top: -2px;
    }
    & > span {
        height: 40px;
    }
}
