@import (reference) '../../../components/style/global.less';

.submenuContainer {
    border-bottom: 1px solid @alude-gray-4;
    width: 100%;
    position: sticky;
    top: 64px;
    background-color: #fff;
    z-index: 3;
}

.submenu {
    width: 100%;
    max-width: 1504px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    height: 48px;
    white-space: nowrap;
    align-items: center;
}

.submenu > span {
    color: @alude-gray-9;
    border-right: 1px solid @alude-gray-5;
    padding: 0 32px 0 0;
    margin: 0 16px 0 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    user-select: none;
}

.submenu a {
    color: @alude-gray-9;
    font-size: 16px;
    padding: 8px 16px;
    line-height: 20px;
}

.submenu a.selected {
    box-shadow: inset 0 0 0 1px @alude-gray-9;
    border-radius: 16px;
}

.caret {
    width: 32px;
    display: none !important;
}

.caret svg {
    font-size: 12px;
}

@media (max-width: @mobile-max) {
    .submenu {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 8px;
    }

    .options {
        flex-direction: column;
        align-items: center;
        width: 100%;
        display: none;
    }

    .submenu.open .options {
        display: flex;
    }

    .submenu > span {
        width: 100%;
        border: 0;
        padding: 8px 0;
        cursor: pointer;
    }

    .submenu a {
        width: 100%;
        padding: 8px 32px;
    }

    .submenu a.selected {
        box-shadow: none;
        border-radius: 16px;
        border-radius: 0;
        background-color: @alude-blue-1;
    }

    .caret {
        display: initial !important;
    }
}
