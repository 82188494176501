@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/form/style/index.less';
@import (reference) 'antd/lib/tooltip/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

@import 'antd/lib/form/style/vertical.less';
@import 'antd/lib/form/style/inline.less';

@media (max-width: @screen-xs-max) {
}

.alude-form {
    .alude-form();

    &-large.alude-form-horizontal .alude-form-item-label > label,
    &-large.alude-form-inline .alude-form-item-label > label {
        line-height: @input-height-lg;
    }

    &-small.alude-form-horizontal .alude-form-item-label > label,
    &-small.alude-form-inline .alude-form-item-label > label {
        line-height: @input-height-sm;
    }

    &-middle.alude-form-horizontal .alude-form-item-label > label,
    &-middle.alude-form-inline .alude-form-item-label > label {
        line-height: @input-height-base;
    }

    &-large.alude-form-vertical,
    &-small.alude-form-vertical,
    &.alude-form-vertical {
        .alude-form-item-label > label {
            line-height: 22px;
        }
    }

    &-large,
    &-small,
    & {
        .alude-form-item-label > label {
            height: auto;
        }
    }

    &-horizontal,
    &-inline {
        & .alude-form-item-label {
            // added because of tag list inside form.item
            min-width: max-content;
        }
        @media (min-width: @screen-xs-max) {
            .alude-form-item-label-description {
                width: 0;
                flex-grow: 1;
            }
        }
    }
}

.alude-form-item {
    .alude-form-item();
    flex-wrap: nowrap; // added because of tag list inside form.item

    &:last-of-type {
        margin-bottom: 0;
    }

    .alude-form-item-label-description {
        order: 1;
        flex-basis: 100%;

        &:extend(.regular-alude);
        color: @alude-gray-7;
        white-space: normal;
    }

    &-label {
        > label {
            padding-right: 8px;
            flex-wrap: wrap;
            color: @alude-gray-9;
            &:extend(.semibold-alude);
            line-height: @input-height-base;

            .alude-form-item-optional,
            .alude-form-item-tooltip {
                color: @alude-gray-color;
                &:extend(.regular-alude);
                line-height: 0px;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            &::after {
                margin-right: 0;
            }
        }
    }

    &-explain {
        color: @alude-gray-7;
        &:extend(.regular-alude);
    }

    &-control {
        // added because of tag list inside form.item
        flex-grow: unset;
        flex-basis: unset;
    }

    .feedbackColors(@hoverColor, @focusColor, @borders: true) {
        &.alude-form-item-has-feedback {
            & when (@borders = true) {
                & .alude-input:not(focus):not(hover) {
                    border-color: @alude-border-color;
                }

                & .alude-input:not(.alude-input-disabled):hover {
                    border-color: @hoverColor;
                }

                & .alude-input:focus {
                    border-color: @focusColor;
                }
            }

            .alude-form-item-control-input-content {
                &:hover ~ .alude-form-item-children-icon {
                    color: @hoverColor;
                }

                &:focus-within ~ .alude-form-item-children-icon {
                    color: @focusColor;
                }
            }
        }
    }

    &-has-success {
        .feedbackColors(@alude-green-5, @alude-green-7, false);
    }

    &-has-warning {
        .feedbackColors(@gold-5, @gold-7);
        .alude-form-item-explain {
            color: @gold-7;
        }
    }

    &-has-error {
        .feedbackColors(@alude-red-5, @alude-red-7);
    }
}
