@import (reference) 'antd/lib/style/color/colorPalette.less';

@prime-gradient-background: linear-gradient(90deg, #fc9842 2.39%, #fe5f75 100%);
@alude-gradient-green-background: linear-gradient(
    270deg,
    #201d64 0%,
    #29cc85 100%
);

@alude-blue-base: #3366ff;
@alude-blue-1: color(~`colorPalette('@{alude-blue-6}', 1) `);
@alude-blue-2: color(~`colorPalette('@{alude-blue-6}', 2) `);
@alude-blue-3: color(~`colorPalette('@{alude-blue-6}', 3) `);
@alude-blue-4: color(~`colorPalette('@{alude-blue-6}', 4) `);
@alude-blue-5: color(~`colorPalette('@{alude-blue-6}', 5) `);
@alude-blue-6: @alude-blue-base;
@alude-blue-7: color(~`colorPalette('@{alude-blue-6}', 7) `);
@alude-blue-8: color(~`colorPalette('@{alude-blue-6}', 8) `);
@alude-blue-9: color(~`colorPalette('@{alude-blue-6}', 9) `);
@alude-blue-10: color(~`colorPalette('@{alude-blue-6}', 10) `);
@alude-blue-0: hsv(
    hsvhue(@alude-blue-1),
    max(hsvsaturation(@alude-blue-1) - 4%, 2%),
    hsvvalue(@alude-blue-1)
);

@alude-orange-base: #f78b22;
@alude-orange-1: color(~`colorPalette('@{alude-orange-6}', 1) `);
@alude-orange-2: color(~`colorPalette('@{alude-orange-6}', 2) `);
@alude-orange-3: color(~`colorPalette('@{alude-orange-6}', 3) `);
@alude-orange-4: color(~`colorPalette('@{alude-orange-6}', 4) `);
@alude-orange-5: color(~`colorPalette('@{alude-orange-6}', 5) `);
@alude-orange-6: @alude-orange-base;
@alude-orange-7: color(~`colorPalette('@{alude-orange-6}', 7) `);
@alude-orange-8: color(~`colorPalette('@{alude-orange-6}', 8) `);
@alude-orange-9: color(~`colorPalette('@{alude-orange-6}', 9) `);
@alude-orange-10: color(~`colorPalette('@{alude-orange-6}', 10) `);
@alude-orange-0: hsv(
    hsvhue(@alude-orange-1),
    max(hsvsaturation(@alude-orange-1) - 4%, 2%),
    hsvvalue(@alude-orange-1)
);

@alude-green-base: #52bd95;
@alude-green-1: color(~`colorPalette('@{alude-green-6}', 1) `);
@alude-green-2: color(~`colorPalette('@{alude-green-6}', 2) `);
@alude-green-3: color(~`colorPalette('@{alude-green-6}', 3) `);
@alude-green-4: color(~`colorPalette('@{alude-green-6}', 4) `);
@alude-green-5: color(~`colorPalette('@{alude-green-6}', 5) `);
@alude-green-6: @alude-green-base;
@alude-green-7: color(~`colorPalette('@{alude-green-6}', 7) `);
@alude-green-8: color(~`colorPalette('@{alude-green-6}', 8) `);
@alude-green-9: color(~`colorPalette('@{alude-green-6}', 9) `);
@alude-green-10: color(~`colorPalette('@{alude-green-6}', 10) `);
@alude-green-0: hsv(
    hsvhue(@alude-green-1),
    max(hsvsaturation(@alude-green-1) - 4%, 2%),
    hsvvalue(@alude-green-1)
);

@alude-red-orange: #f3541e;
@alude-red-base: #d13b3b;
@alude-red-1: color(~`colorPalette('@{alude-red-6}', 1) `);
@alude-red-2: color(~`colorPalette('@{alude-red-6}', 2) `);
@alude-red-3: color(~`colorPalette('@{alude-red-6}', 3) `);
@alude-red-4: color(~`colorPalette('@{alude-red-6}', 4) `);
@alude-red-5: color(~`colorPalette('@{alude-red-6}', 5) `);
@alude-red-6: @alude-red-base;
@alude-red-7: color(~`colorPalette('@{alude-red-6}', 7) `);
@alude-red-8: color(~`colorPalette('@{alude-red-6}', 8) `);
@alude-red-9: color(~`colorPalette('@{alude-red-6}', 9) `);
@alude-red-10: color(~`colorPalette('@{alude-red-6}', 10) `);
@alude-red-0: hsv(
    hsvhue(@alude-red-1),
    max(hsvsaturation(@alude-red-1) - 4%, 2%),
    hsvvalue(@alude-red-1)
);

@alude-minsk-base: #101840;
@alude-minsk-1: lighten(@alude-minsk-base, 80%);
@alude-minsk-2: lighten(@alude-minsk-base, 70%);
@alude-minsk-3: lighten(@alude-minsk-base, 60%);
@alude-minsk-4: lighten(@alude-minsk-base, 50%);
@alude-minsk-5: lighten(@alude-minsk-base, 40%);
@alude-minsk-6: lighten(@alude-minsk-base, 30%);
@alude-minsk-7: lighten(@alude-minsk-base, 20%);
@alude-minsk-8: lighten(@alude-minsk-base, 10%);
@alude-minsk-9: @alude-minsk-base;
@alude-minsk-10: darken(@alude-minsk-base, 5%);
@alude-minsk-0: hsv(
    hsvhue(@alude-minsk-1),
    max(hsvsaturation(@alude-minsk-1) - 4%, 2%),
    hsvvalue(@alude-minsk-1)
);

@alude-storm-base: #696f8c;
@alude-storm-1: color(~`colorPalette('@{alude-storm-6}', 1) `);
@alude-storm-2: color(~`colorPalette('@{alude-storm-6}', 2) `);
@alude-storm-3: color(~`colorPalette('@{alude-storm-6}', 3) `);
@alude-storm-4: color(~`colorPalette('@{alude-storm-6}', 4) `);
@alude-storm-5: color(~`colorPalette('@{alude-storm-6}', 5) `);
@alude-storm-6: @alude-storm-base;
@alude-storm-7: color(~`colorPalette('@{alude-storm-6}', 7) `);
@alude-storm-8: color(~`colorPalette('@{alude-storm-6}', 8) `);
@alude-storm-9: color(~`colorPalette('@{alude-storm-6}', 9) `);
@alude-storm-10: color(~`colorPalette('@{alude-storm-6}', 10) `);
@alude-storm-0: hsv(
    hsvhue(@alude-storm-1),
    max(hsvsaturation(@alude-storm-1) - 4%, 2%),
    hsvvalue(@alude-storm-1)
);

@alude-white-opac-10: rgba(255, 255, 255, 0.1);
@alude-white-opac-70: rgba(255, 255, 255, 0.7);
@alude-gray-0: #fff;
@alude-gray-1: #fafafa;
@alude-gray-2: #f5f5f5;
@alude-gray-3: #f0f0f0;
@alude-gray-4: #d9d9d9;
@alude-gray-5: #bfbfbf;
@alude-gray-6: #8c8c8c;
@alude-gray-7: #595959;
@alude-gray-8: #434343;
@alude-gray-9: #262626;
@alude-gray-10: #1f1f1f;
@alude-gray-11: #141414;
@alude-gray-12: #000;

.alude-blue-1 {
    color: @alude-blue-1;
}
.alude-blue-2 {
    color: @alude-blue-2;
}
.alude-blue-3 {
    color: @alude-blue-3;
}
.alude-blue-4 {
    color: @alude-blue-4;
}
.alude-blue-5 {
    color: @alude-blue-5;
}
.alude-blue-6 {
    color: @alude-blue-6;
}
.alude-blue-7 {
    color: @alude-blue-7;
}
.alude-blue-8 {
    color: @alude-blue-8;
}
.alude-blue-9 {
    color: @alude-blue-9;
}
.alude-blue-10 {
    color: @alude-blue-10;
}
.alude-blue-0 {
    color: @alude-blue-0;
}
.alude-orange-1 {
    color: @alude-orange-1;
}
.alude-orange-2 {
    color: @alude-orange-2;
}
.alude-orange-3 {
    color: @alude-orange-3;
}
.alude-orange-4 {
    color: @alude-orange-4;
}
.alude-orange-5 {
    color: @alude-orange-5;
}
.alude-orange-6 {
    color: @alude-orange-6;
}
.alude-orange-7 {
    color: @alude-orange-7;
}
.alude-orange-8 {
    color: @alude-orange-8;
}
.alude-orange-9 {
    color: @alude-orange-9;
}
.alude-orange-10 {
    color: @alude-orange-10;
}
.alude-orange-0 {
    color: @alude-orange-0;
}
.alude-green-1 {
    color: @alude-green-1;
}
.alude-green-2 {
    color: @alude-green-2;
}
.alude-green-3 {
    color: @alude-green-3;
}
.alude-green-4 {
    color: @alude-green-4;
}
.alude-green-5 {
    color: @alude-green-5;
}
.alude-green-6 {
    color: @alude-green-6;
}
.alude-green-7 {
    color: @alude-green-7;
}
.alude-green-8 {
    color: @alude-green-8;
}
.alude-green-9 {
    color: @alude-green-9;
}
.alude-green-10 {
    color: @alude-green-10;
}
.alude-green-0 {
    color: @alude-green-0;
}
.alude-red-orange {
    color: @alude-red-orange;
}
.bg-alude-red-orange {
    background-color: @alude-red-orange;
}
.alude-red-1 {
    color: @alude-red-1;
}
.alude-red-2 {
    color: @alude-red-2;
}
.alude-red-3 {
    color: @alude-red-3;
}
.alude-red-4 {
    color: @alude-red-4;
}
.alude-red-5 {
    color: @alude-red-5;
}
.alude-red-6 {
    color: @alude-red-6;
}
.alude-red-7 {
    color: @alude-red-7;
}
.alude-red-8 {
    color: @alude-red-8;
}
.alude-red-9 {
    color: @alude-red-9;
}
.alude-red-10 {
    color: @alude-red-10;
}
.alude-red-0 {
    color: @alude-red-0;
}
.alude-minsk-1 {
    color: @alude-minsk-1;
}
.alude-minsk-2 {
    color: @alude-minsk-2;
}
.alude-minsk-3 {
    color: @alude-minsk-3;
}
.alude-minsk-4 {
    color: @alude-minsk-4;
}
.alude-minsk-5 {
    color: @alude-minsk-5;
}
.alude-minsk-6 {
    color: @alude-minsk-6;
}
.alude-minsk-7 {
    color: @alude-minsk-7;
}
.alude-minsk-8 {
    color: @alude-minsk-8;
}
.alude-minsk-9 {
    color: @alude-minsk-9;
}
.alude-minsk-10 {
    color: @alude-minsk-10;
}
.alude-minsk-0 {
    color: @alude-minsk-0;
}
.alude-storm-1 {
    color: @alude-storm-1;
}
.alude-storm-2 {
    color: @alude-storm-2;
}
.alude-storm-3 {
    color: @alude-storm-3;
}
.alude-storm-4 {
    color: @alude-storm-4;
}
.alude-storm-5 {
    color: @alude-storm-5;
}
.alude-storm-6 {
    color: @alude-storm-6;
}
.alude-storm-7 {
    color: @alude-storm-7;
}
.alude-storm-8 {
    color: @alude-storm-8;
}
.alude-storm-9 {
    color: @alude-storm-9;
}
.alude-storm-10 {
    color: @alude-storm-10;
}
.alude-storm-0 {
    color: @alude-storm-0;
}
.alude-gray-0 {
    color: @alude-gray-0;
}
.alude-gray-1 {
    color: @alude-gray-1;
}
.alude-gray-2 {
    color: @alude-gray-2;
}
.alude-gray-3 {
    color: @alude-gray-3;
}
.alude-gray-4 {
    color: @alude-gray-4;
}
.alude-gray-5 {
    color: @alude-gray-5;
}
.alude-gray-6 {
    color: @alude-gray-6;
}
.alude-gray-7 {
    color: @alude-gray-7;
}
.alude-gray-8 {
    color: @alude-gray-8;
}
.alude-gray-9 {
    color: @alude-gray-9;
}
.alude-gray-10 {
    color: @alude-gray-10;
}
.alude-gray-11 {
    color: @alude-gray-11;
}
.alude-gray-12 {
    color: @alude-gray-12;
}
.alude-white-opac-70 {
    color: @alude-white-opac-70;
}
.alude-white-opac-10 {
    color: @alude-white-opac-10;
}

.bg-alude-blue-1 {
    background-color: @alude-blue-1;
}
.bg-alude-blue-2 {
    background-color: @alude-blue-2;
}
.bg-alude-blue-3 {
    background-color: @alude-blue-3;
}
.bg-alude-blue-4 {
    background-color: @alude-blue-4;
}
.bg-alude-blue-5 {
    background-color: @alude-blue-5;
}
.bg-alude-blue-6 {
    background-color: @alude-blue-6;
}
.bg-alude-blue-7 {
    background-color: @alude-blue-7;
}
.bg-alude-blue-8 {
    background-color: @alude-blue-8;
}
.bg-alude-blue-9 {
    background-color: @alude-blue-9;
}
.bg-alude-blue-10 {
    background-color: @alude-blue-10;
}
.bg-alude-blue-0 {
    background-color: @alude-blue-0;
}
.bg-alude-orange-1 {
    background-color: @alude-orange-1;
}
.bg-alude-orange-2 {
    background-color: @alude-orange-2;
}
.bg-alude-orange-3 {
    background-color: @alude-orange-3;
}
.bg-alude-orange-4 {
    background-color: @alude-orange-4;
}
.bg-alude-orange-5 {
    background-color: @alude-orange-5;
}
.bg-alude-orange-6 {
    background-color: @alude-orange-6;
}
.bg-alude-orange-7 {
    background-color: @alude-orange-7;
}
.bg-alude-orange-8 {
    background-color: @alude-orange-8;
}
.bg-alude-orange-9 {
    background-color: @alude-orange-9;
}
.bg-alude-orange-10 {
    background-color: @alude-orange-10;
}
.bg-alude-orange-0 {
    background-color: @alude-orange-0;
}
.bg-alude-green-1 {
    background-color: @alude-green-1;
}
.bg-alude-green-2 {
    background-color: @alude-green-2;
}
.bg-alude-green-3 {
    background-color: @alude-green-3;
}
.bg-alude-green-4 {
    background-color: @alude-green-4;
}
.bg-alude-green-5 {
    background-color: @alude-green-5;
}
.bg-alude-green-6 {
    background-color: @alude-green-6;
}
.bg-alude-green-7 {
    background-color: @alude-green-7;
}
.bg-alude-green-8 {
    background-color: @alude-green-8;
}
.bg-alude-green-9 {
    background-color: @alude-green-9;
}
.bg-alude-green-10 {
    background-color: @alude-green-10;
}
.bg-alude-green-0 {
    background-color: @alude-green-0;
}
.bg-alude-red-1 {
    background-color: @alude-red-1;
}
.bg-alude-red-2 {
    background-color: @alude-red-2;
}
.bg-alude-red-3 {
    background-color: @alude-red-3;
}
.bg-alude-red-4 {
    background-color: @alude-red-4;
}
.bg-alude-red-5 {
    background-color: @alude-red-5;
}
.bg-alude-red-6 {
    background-color: @alude-red-6;
}
.bg-alude-red-7 {
    background-color: @alude-red-7;
}
.bg-alude-red-8 {
    background-color: @alude-red-8;
}
.bg-alude-red-9 {
    background-color: @alude-red-9;
}
.bg-alude-red-10 {
    background-color: @alude-red-10;
}
.bg-alude-red-0 {
    background-color: @alude-red-0;
}
.bg-alude-minsk-1 {
    background-color: @alude-minsk-1;
}
.bg-alude-minsk-2 {
    background-color: @alude-minsk-2;
}
.bg-alude-minsk-3 {
    background-color: @alude-minsk-3;
}
.bg-alude-minsk-4 {
    background-color: @alude-minsk-4;
}
.bg-alude-minsk-5 {
    background-color: @alude-minsk-5;
}
.bg-alude-minsk-6 {
    background-color: @alude-minsk-6;
}
.bg-alude-minsk-7 {
    background-color: @alude-minsk-7;
}
.bg-alude-minsk-8 {
    background-color: @alude-minsk-8;
}
.bg-alude-minsk-9 {
    background-color: @alude-minsk-9;
}
.bg-alude-minsk-10 {
    background-color: @alude-minsk-10;
}
.bg-alude-minsk-0 {
    background-color: @alude-minsk-0;
}
.bg-alude-storm-1 {
    background-color: @alude-storm-1;
}
.bg-alude-storm-2 {
    background-color: @alude-storm-2;
}
.bg-alude-storm-3 {
    background-color: @alude-storm-3;
}
.bg-alude-storm-4 {
    background-color: @alude-storm-4;
}
.bg-alude-storm-5 {
    background-color: @alude-storm-5;
}
.bg-alude-storm-6 {
    background-color: @alude-storm-6;
}
.bg-alude-storm-7 {
    background-color: @alude-storm-7;
}
.bg-alude-storm-8 {
    background-color: @alude-storm-8;
}
.bg-alude-storm-9 {
    background-color: @alude-storm-9;
}
.bg-alude-storm-10 {
    background-color: @alude-storm-10;
}
.bg-alude-storm-0 {
    background-color: @alude-storm-0;
}
.bg-alude-gray-0 {
    background-color: @alude-gray-0;
}
.bg-alude-gray-1 {
    background-color: @alude-gray-1;
}
.bg-alude-gray-2 {
    background-color: @alude-gray-2;
}
.bg-alude-gray-3 {
    background-color: @alude-gray-3;
}
.bg-alude-gray-4 {
    background-color: @alude-gray-4;
}
.bg-alude-gray-5 {
    background-color: @alude-gray-5;
}
.bg-alude-gray-6 {
    background-color: @alude-gray-6;
}
.bg-alude-gray-7 {
    background-color: @alude-gray-7;
}
.bg-alude-gray-8 {
    background-color: @alude-gray-8;
}
.bg-alude-gray-9 {
    background-color: @alude-gray-9;
}
.bg-alude-gray-10 {
    background-color: @alude-gray-10;
}
.bg-alude-gray-11 {
    background-color: @alude-gray-11;
}
.bg-alude-gray-12 {
    background-color: @alude-gray-12;
}
.bg-alude-white-opac-70 {
    background-color: @alude-white-opac-70;
}
.bg-alude-white-opac-10 {
    background-color: @alude-white-opac-10;
}
