@import (reference) '../../style/global.less';

@keyframes infiniteScrollLeft {
    from {
        transform: translateX(calc(0%));
    }
    to {
        transform: translateX(calc(-100%));
    }
}

.logos {
    position: relative;
    overflow: hidden !important;

    & > div {
        animation: infiniteScrollLeft 20s linear 0s infinite normal none running;
        min-width: 100%;

        & > * {
            padding: 0 24px;
            width: 200px;
            height: auto;
            filter: brightness(0) saturate(100%) invert(70%);
        }
    }

    &:before,
    &:after {
        box-sizing: border-box;
        content: '';
        height: 100%;
        width: clamp(40px, 13.8vw, 200px);
        position: absolute;
        top: 0px;
        z-index: 1;
    }

    &:before {
        left: 0px;
        background: linear-gradient(
            to right,
            var(--alude-page-bg-color),
            rgba(255, 255, 255, 0)
        );
    }

    &:after {
        right: 0px;
        background: linear-gradient(
            to left,
            var(--alude-page-bg-color),
            rgba(255, 255, 255, 0)
        );
    }
}

.labels {
    & svg {
        color: @alude-red-orange;
    }

    & span {
        flex-shrink: 1;
    }
}
