@import './fonts/roboto/roboto.css';
@import './components/style/global.less';

body {
    font-family: @alude-font-family;
    font-weight: @alude-font-weight-base;
    font-style: normal;
    color: @alude-gray-9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@import './print.less';
@import 'plyr/dist/plyr.css';
