@import (reference) '../../style/global.less';

.imageWrapper {
    height: auto;
    position: relative;

    & img {
        aspect-ratio: 3/2;
        height: auto;
        width: 100%;
        object-fit: cover;
    }
}

.paddedImageWrapper {
    & img {
        padding: 4.5% 6.874% 4.6%;
    }
}

.homeImageWrapper {
    max-width: 700px;
}

.stroke {
    position: absolute;
    top: 0px;
    width: 100%;
}

.container {
    &.reversed {
        & .imageWrapper {
            order: 0;
        }

        & .text {
            order: 1;
        }
    }

    @media all and (max-width: @tablet-max) {
        & .imageWrapper {
            max-width: 100%;
            order: 0;
        }

        & .text {
            order: 1;
        }
    }
}
