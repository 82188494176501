@import (reference) '../style/global.less';

.aldBreadcrumb {
    &:extend(.horizontal);
    &:extend(.horizontal.left-middle);
    flex-wrap: wrap;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    & > span {
        &:extend(.horizontal.left-middle);

        display: inline-flex;
        flex-direction: row-reverse;

        &:last-child :global(.ant-breadcrumb-separator) {
            display: inline-flex;
        }

        &:first-child :global(.ant-breadcrumb-separator) {
            display: none;
        }
    }

    :global(.ant-breadcrumb-separator) {
        display: inline-flex;

        svg {
            font-size: 8px;
        }
    }
}
