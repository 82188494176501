@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/input/style/index.less';
@import (reference) 'antd/lib/button/style/index.less';

@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-input {
    .alude-input();
    border-radius: @alude-border-radius;

    &-lg {
        font-size: @font-size-base;
    }

    &-affix-wrapper svg {
        font-size: @font-size-lg;
    }

    &:active,
    &:focus {
        border-color: @alude-blue-7;
    }

    &-clear-icon-hidden,
    &-clear-icon {
        display: none;
    }
}
