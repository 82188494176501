@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/modal/style/index.less';
@import (reference) '../style/ant-overrides.less';

@ant-prefix: ~'ald';
@dialog-prefix-cls: ~'ald-modal';

.ald-modal {
    .ald-modal();
}

.@{dialog-prefix-cls} {
    border-radius: 8px;

    &-header {
        border-radius: 8px 8px 0 0;
    }

    &-title {
        display: flex;
        align-items: center;
    }

    &-footer {
        border-radius: 0 0 8px 8px;

        &:extend(.horizontal);
        &:extend(.horizontal.right-top);
        &:extend(.g16);

        .@{ant-prefix}-btn
            + .@{ant-prefix}-btn:not(.@{ant-prefix}-dropdown-trigger) {
            margin-bottom: 0;
            margin-left: 0;
        }
    }

    &-content {
        border-radius: 8px;
        box-shadow: none;
        &:extend(.elevation-element);
    }
}

@media (max-width: @screen-sm-max) {
    .@{dialog-prefix-cls} {
        top: 0;
        max-width: 100%;
        height: 100%;

        // please keep the following !important.
        // ant added a hardcoded width in a style attribute.
        width: 100% !important;
        padding: 0;
        margin: 0;

        &-wrap {
            overflow: visible;
        }

        &-header {
            border-radius: 0;
        }

        &-content {
            border-radius: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &-body {
            overflow: auto;
            flex: 1;
        }

        &-footer {
            border-radius: 0;

            &:extend(.horizontal);
            &:extend(.horizontal.stretch-top);
            &:extend(.p16);

            > * {
                flex: 1;
            }

            > button {
                height: auto;
                white-space: normal;
            }
        }
    }
}
