@import (reference) 'MenuMixin.less';
@import (reference) '../style/global.less';

@menu-icon-size: 16px;
@menu-item-color: @alude-storm-6;
@menu-highlight-color: @alude-gray-9;
@menu-item-active-bg: @alude-blue-1;
@menu-bg: transparent;
@menu-item-padding-horizontal: 0;

@menu-prefix-cls: ~'alude-contextual-menu';
@cls: @menu-prefix-cls;

.alude-contextual-menu {
    .alude-contextual-menu();
}

.alude-menu-wrapper.@{cls} {
    overflow-x: auto;

    & .@{cls}-item {
        color: @alude-gray-9;
        width: 100%;
        padding: 4px 12px;
        height: auto;
        &:extend(.regular-alude);
    }

    .@{cls}-item:hover:not(.@{cls}-item-selected) {
        background-color: @alude-gray-1;
        color: @alude-gray-9;
    }

    .@{cls}-item-disabled,
    .@{cls}-submenu-disabled {
        // Ant uses !important in this rule as well
        color: @alude-gray-4 !important;
    }
}
