@import (reference) '../../../../components/style/global.less';

div.bodyContainer {
    overflow: scroll;
    height: 100%;
}

.searchCard {
    &:hover {
        background-color: @alude-gray-2;
    }
}

.formItem {
    &:global(.alude-form-item) {
        margin-bottom: 0;
    }
}
