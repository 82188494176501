@import (reference) '../../style/global.less';

// Using body + :where ensures that specifity of 1 (instead of 10)
// is given to .override
body :where(.override) {
    color: @alude-gray-7;

    h1,
    h2,
    h3,
    h4 {
        color: @alude-gray-8;
    }

    p {
        margin-bottom: 0;
    }
}
