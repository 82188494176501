@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/tooltip/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-tooltip {
    .alude-tooltip();
    &:extend(.regular-alude);

    & &-content {
        &:extend(.elevation-element);
    }
}
