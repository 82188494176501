@import 'ButtonMixin';
@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/button/style/index.less';
@import (reference) '../style/ant-overrides.less';

@primary-color: @alude-accent-color;
@link-color: @alude-accent-color;

@override-btn-mixins();

@btn-prefix-cls: ~'ald-btn-secondary';

.ald-btn-secondary {
    .ald-btn-secondary();
}
