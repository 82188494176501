@import (reference) '../../style/global.less';

.menuContainer {
    position: fixed;
    top: 64px;
    left: 0px;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 64px);
    background-color: white;
    overflow-y: auto !important;
}

.menuItems {
    & > * {
        border-bottom: 1px solid @alude-gray-4;
    }

    & > div {
        border-bottom: 1px solid @alude-gray-4;
    }
}

.item {
    &:extend(.semilarge-alude);
    &:extend(.semibold);
    color: @alude-gray-9;
    padding: 16px 0;
    &:global(.lp-collapse) {
        padding: 16px 0;
    }

    & div {
        padding: 0;
    }
}

.subItem {
    & svg {
        color: @alude-red-orange;
        width: 1em !important;
    }
    &:extend(.medium-alude);
}

.buttons {
    &:global(.horizontal.stretch-middle) > * {
        flex: 1 1 0.001px;
    }
}
