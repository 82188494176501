@import 'ButtonMixin';
@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/button/style/index.less';
@import (reference) '../style/ant-overrides.less';

@override-btn-mixins();

@btn-prefix-cls: ~'ald-btn';

.ald-btn {
    .ald-btn();
}
