@import (reference) 'MenuMixin.less';
@import (reference) '../style/global.less';

@menu-icon-size: 16px;
@menu-item-color: @alude-storm-6;
@menu-highlight-color: @alude-blue-6;
@menu-item-active-bg: @alude-blue-0;
@menu-bg: transparent;
@menu-item-padding-horizontal: 0;
@border-width-base: 0;

@menu-prefix-cls: ~'alude-navigation-menu';

.alude-navigation-menu {
    .alude-navigation-menu();

    overflow-x: auto;

    &-overflow {
        max-width: 100%;
        &.horizontal {
            flex-wrap: wrap;
        }
    }

    &-horizontal .@{menu-prefix-cls}-item {
        margin-right: 20px;
    }

    &-inline {
        .@{menu-prefix-cls}-item,
        .@{menu-prefix-cls}-submenu-title {
            width: 100%;
        }
    }

    .@{menu-prefix-cls}-item {
        top: unset;
    }

    .@{menu-prefix-cls}-item:hover:not(.@{menu-prefix-cls}-item-selected) {
        color: @alude-blue-5;
    }
}
