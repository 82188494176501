@import (reference) '../style/global.less';

.focus {
    position: relative;

    & > * {
        z-index: 2;
        position: relative;
    }

    &:after {
        display: block;
        content: ' ';
        position: absolute;
        left: -8px;
        right: -8px;
        top: -8px;
        bottom: -8px;
        border-radius: 8px;
        background: white;
        z-index: 1;
        opacity: 0;
        animation: focus-animation 1s linear 2;
    }

    &:before {
        display: block;
        content: ' ';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(black, 0.2);
        z-index: 1;
        animation: focus-animation 1s linear 2;
        opacity: 0;
    }
}

@keyframes focus-animation {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
