@import (reference) '../../../components/style/global.less';

.img > img {
    width: 100%;
}

.container {
    margin-bottom: -140px;
    margin-top: 32px;

    @media (max-width: @mobile-max) {
        margin-bottom: -48px;
        margin-top: 24px;
    }
}
