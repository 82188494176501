.breadcrumb {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    flex: 1 !important;
}

.verticalBreadcrumb {
    &:extend(.breadcrumb);
    flex-direction: column !important;
}

.firstVerticalBreadcrumb {
    padding-left: 20px;
}

.textWrap {
    white-space: break-spaces;
}

.dropdown {
    flex: 1 !important;
}
