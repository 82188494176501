@import (reference) '../style/global.less';

div.content {
    max-width: 400px;
    position: relative;
    flex: 0 0 auto !important;

    & img {
        width: 100%;
        height: auto;
    }
}

.video {
    padding-bottom: 225px;
    height: 0;
    position: relative;
}

@media all and (max-width: @tablet-max) {
    .video {
        padding-bottom: 56.25%;
    }

    div.content {
        max-width: unset;
    }
}

.posterPlayButton {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    color: white !important;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: @alude-red-orange !important;
    padding: 12px 16px;
    font-size: 18px;
}

.closeIcon {
    position: absolute;
    top: -50px;
    right: 0;
}

.modalVideo {
    & :global(.ald-modal-body) {
        border-radius: 8px;
        padding: 0;
        background-color: @alude-gray-8;
        overflow: hidden;

        & video,
        & iframe {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: 100%;
        }
    }

    & :global(.ald-modal-content) {
        height: auto;
        top: calc(50% - 0.5 * 100vw / 16 * 9);
        border-radius: 8px;
    }

    & :global(.ald-modal) {
        height: auto;
        top: calc(50% - 0.5 * 100vw / 16 * 9);
    }
}
