@import (reference) '../../style/global.less';

.icon {
    color: @volcano-6;
    &:extend(.large-alude);
}

.item {
    max-width: 272px;
    min-width: 130px;
}
