@import (reference) '../../style/global.less';

.features {
    &:global(.horizontal) {
        overflow-x: auto;
    }
    & > div {
        min-width: 250px;
        max-width: 330px;
        flex-shrink: 1;
    }

    justify-content: center;

    // instead of using flex gap, I use padding here to give the spacing we
    // need so that the box-shadow is not hidden by the overflow-x.
    padding-top: 40px;
    margin-bottom: -40px; // workaround to not add to the section gap
    padding-bottom: 40px;
}

// Work around: I wanted it to be centered and
// overflow-x auto but could not make that work with
// more than 3 features in the screen
@media (max-width: 1200px) {
    .features {
        justify-content: left;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}
