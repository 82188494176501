@import (reference) '../../style/global.less';

.container {
    width: 100%;
}

.footer {
    max-width: 1504px;
    width: 100%;
}

.links {
    display: grid;
    column-gap: 40px;
    row-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
}

.logo {
    width: 16px;
}

.hr {
    border-color: @alude-white-opac-10;
    margin: 0;
    width: 100%;
}

.company {
    flex: unset;
    min-width: 240px;
}

@media (max-width: @tablet-max) {
    .company {
        text-align: center;
    }
}

.socialMedia {
    font-size: 24px;
}

.socialMediaLink {
    &:hover,
    &:focus,
    &:active {
        color: @alude-gray-0;
    }
}

.linkChild {
    color: @alude-white-opac-70;
    transition: color @ant-transition-duration;

    &:hover,
    &:focus,
    &:active {
        color: @alude-gray-0;
    }
}
