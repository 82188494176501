@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/carousel/style/index.less';
@import (reference) '../style/global.less';
@import (reference) '../style/ant-overrides.less';

@carousel-prefix-cls: ~'ald-carousel';

.@{carousel-prefix-cls} {
    min-height: @btn-height-lg;

    .slick-dots {
        width: min-content;
        background: rgba(0, 0, 0, 0.5);
        border-radius: @border-radius-base;
        margin-left: auto;
        margin-right: auto;
        padding-left: 8px;

        li {
            margin: 0;
            button {
                border-radius: 50%;
            }
        }

        &:extend(.horizontal,
        .p8,
        .center-middle,
        .g12);
    }

    .slick-dots-bottom {
        bottom: 10px;
    }
}

.ald-carousel {
    .ald-carousel();
}
