@import (reference) '../../../components/style/global.less';

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    & > *:not(.pricingSection) {
        display: flex;
        justify-content: center;
        width: 100%;
        &:extend(.d-px32);
        &:extend(.mt-px16);

        & > *:not(.pricingSection) {
            width: 100%;
        }
    }

    & > .pricingSection {
        width: 100%;
    }
}

@media (max-width: @mobile-max) {
    .page {
        row-gap: 50px;
    }
}
@media (min-width: @mobile-max) {
    .page {
        row-gap: 112px;
    }
}
