@import (reference) '../../../components/style/global.less';

.newsletter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid @alude-gray-4;
    padding: 32px;
    border-radius: 24px;
}

.newsletter > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-grow: 1;
    max-width: 520px;
}

.newsletter > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.newsletter > div:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 1;
}

.newsletter > div:nth-child(1) p {
    font-size: 16px;
}

.newsletter h2 {
    font-size: 24px;
    font-weight: normal;
}

.newsletter img {
    width: 264px;
}

@media (max-width: @tablet-max) {
    .newsletter {
        flex-direction: column;
    }
}
