@import (reference) '../../style/global.less';

.title {
    &:extend(.medium-alude);
    color: @alude-gray-9;
}

.card {
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid @alude-gray-4;
}

.card:hover {
    box-shadow: 4px 4px 16px 0 @alude-gray-2;
}

.image {
    overflow: hidden;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    aspect-ratio: 4.5 / 3;

    & > div > span {
        position: absolute;
        top: 25px;
        right: 40px;
    }
}

.image div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform: scale(1.1);
}

.synopsis {
    line-height: 1.5em;
}

.container {
    text-decoration: none;
    color: unset;

    & img {
        height: auto;
        width: 100%;
    }

    &:hover {
        & .title {
            color: @alude-blue-6;
        }

        color: unset;
    }
}

.footer {
    justify-content: space-between;
}

.creationDateTag {
    z-index: 2;
}

.readingTimeTag {
    z-index: 1;
    margin-left: -16px;
}
