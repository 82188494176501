@import (reference) '../../style/global.less';

.synopsis {
    border-bottom: 1px solid @alude-gray-4;
}

.synopsis p {
    font-size: 20px;
    font-style: italic;
}

.takeaways {
    background-color: @alude-orange-2;
    padding: 32px 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: -16px;
}

.takeaways h2 {
    font-size: 22px;
}

.takeaways ol {
    margin: 0;
}

.takeaways ol li {
    margin-bottom: 8px;
}

.post .takeaways {
    margin-top: 24px;
    margin-bottom: 8px;
}

.post .takeaways.red {
    background-color: @alude-red-2;
}

.post .takeaways.green {
    background-color: @alude-green-2;
}

.post .takeaways.blue {
    background-color: @alude-blue-2;
}

.post .takeaways.minsk {
    background-color: @alude-minsk-1;
}

.post .takeaways.orange {
    background-color: @alude-orange-2;
}

.post .takeaways h2 {
    font-size: 22px;
    margin: 0px;
}

.post .takeaways ol {
    margin: 0;
}

.post .takeaways ol li {
    margin-bottom: 8px;
}

.post .takeaways p {
    margin-top: 0;
}

.post p {
    margin: 24px 0 0 0;
}

.post h2 {
    font-size: 32px;
    margin: 56px 0 0 0;
}

.post h3 {
    margin: 56px 0 0 0;
}

.post h4 {
    margin: 56px 0 0 0;
}

.post h5 {
    margin: 56px 0 0 0;
}

.post ul,
.post ol {
    margin-top: 20px;
}

.post ul > li:not(:last-child) > ul {
    margin-bottom: 20px;
}

.post iframe {
    margin: 30px 0px;
}

.postTable {
    width: 100%;
    text-align: center;
    margin: 40px 0;
}

.postTable thead tr {
    border-bottom: 1px solid @alude-gray-6;
}

.postTable tbody tr {
    border-bottom: 1px solid @alude-gray-4;
}

.postTable tr td,
.postTable tr th {
    padding: 6px 0;
}

.post :global(.ant-image) {
    max-width: 100%;
    margin: 24px 0;
    align-self: center;
    border-radius: 12px;

    & > :global(.ant-image-mask) {
        border-radius: 12px;
    }
    & > img {
        border-radius: 12px;
    }
}

.post video {
    max-width: 100%;
    margin: 24px 0;
    align-self: center;
    border-radius: 12px;
}

.banner {
    & img {
        width: 100%;
        max-height: 600px;
    }
}

.aludeLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: @alude-gray-10;
}

.authorLogo {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    border-radius: 50%;
}

.authorDetails {
    font-size: 14px;
}

.relatedPostsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
}

@media (max-width: @mobile-max) {
    .relatedPostsGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.postContainer {
    display: flex;
    gap: 64px;
}

.postContainer h2 {
    scroll-margin-top: calc(64px + 32px);
}

.sidebar {
    width: 300px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 32px;
    gap: 16px;
    position: sticky;
    top: calc(64px + 32px);
    margin-bottom: 64px;
}

@media (max-width: @tablet-max) {
    .sidebar {
        width: 100%;
    }
}

@media (min-width: @tablet-max) {
    .sidebar strong {
        font-size: 16px;
    }
}

@media (max-width: @tablet-max) {
    .sidebar strong {
        font-size: 22px;
    }
}

@media (min-width: @tablet-max) {
    .sidebar a {
        font-size: 14px;
    }
}

.sidebarCta {
    border-top: 2px solid @alude-gray-4;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    margin-top: 16px;
}

.author {
    margin: 30px 0px;
    background-color: @alude-gray-2;
    border-radius: 16px;
}

.authorTitle {
    align-items: center;
}

.authorName span {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 500;
}
