@import (reference) '../../style/global.less';

.header {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 3;
    height: 64px;
    width: 100%;
    color: @alude-gray-9;
    border-bottom: 1px solid @alude-gray-4;
    &:extend(.semilarge-alude);

    & a:not(:global(.ald-btn)) {
        text-decoration: none;
        color: unset;
    }
}
