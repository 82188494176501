@import 'colors.less';
@import 'antd/lib/style/color/colors.less';

@alude-page-bg-color: @alude-gray-1;

:root {
    --alude-page-bg-color: @alude-page-bg-color;
}

@lp-section-max-width: 1440px;

.lp-section {
    max-width: @lp-section-max-width;
}

p {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
}

@alude-btn-icon-size: 16px;
@alude-btn-icon-size-lg: 24px;
@alude-primary-color: @alude-blue-6;
@alude-danger-color: @alude-red-6;
@alude-gray-color: @alude-gray-6;
@alude-success-color: @alude-green-6;
@alude-accent-color: @alude-green-6;
@alude-error-color: @alude-red-6;
@alude-normal-color: @alude-gray-5;
@alude-font-weight-base: 400;
@alude-font-size-base: 14px;
@alude-warning-color: @gold-6;
@alude-side-bar-width: 256px;

@alude-border-radius: 4px;
@alude-larger-border-radius: 8px;
@alude-border-color: @alude-gray-5;
@alude-component-border: 1px solid @alude-border-color;
@alude-card-border: 1px solid @alude-gray-4;
@alude-top-bar-height: 60px;

@alude-box-shadow: ~'0px 1px 1px rgba(0, 0, 0, 0.1)';

@alude-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

@component-height-base: 32px;
@component-height-lg: 40px;
@component-height-sm: 24px;

@ant-transition-duration: 0.3s;
@ant-transition-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

@mobile-max: 599.5px;
@tablet-max: 1079.5px;

@import './layout.less';

h1,
h2,
h3,
h4,
h5,
h6,
.h1-alude,
.h2-alude,
.h3-alude,
.h4-alude,
.h5-alude,
.h6-alude {
    // General styles
    font-weight: 500;
    line-height: 120%;
}

// Removing Ant defaults
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
    color: unset;
}

h6,
.h6-alude {
    font-size: 16px;
}

h5,
.h5-alude {
    font-size: 20px;
}

h4,
.h4-alude {
    font-size: clamp(20px, 1.667vw, 24px);
}

h3,
.h3-alude {
    font-size: clamp(24px, 2.222vw, 32px);
}

h2,
.h2-alude {
    font-size: clamp(28px, 2.77vw, 40px);
}

h1,
.h1-alude {
    font-size: clamp(32px, 3.33vw, 48px);
}

.regular-alude {
    font-weight: @alude-font-weight-base;
    font-size: @alude-font-size-base;
    line-height: 22px;
}

b,
strong,
.semibold {
    font-weight: 500;
}

.elevation-element {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.clickable-element {
    box-shadow: @alude-box-shadow;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.danger-text {
    color: @alude-red-7;
}

.secondary-text {
    color: @alude-gray-7;
}

.accent-text {
    color: @alude-green-8;
}

.line-through {
    text-decoration: line-through;
}

.semibold-alude {
    font-weight: 500;
    font-size: @alude-font-size-base;
    line-height: 22px;
}

.superlarge-alude {
    font-size: clamp(24px, 2.22vw, 32px);
    line-height: 120%;
}

.large-alude {
    font-size: 20px;
    line-height: 28px;
}

.semilarge-semibold-alude {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
}

.semilarge-alude {
    font-weight: @alude-font-weight-base;
    font-size: 18px;
    line-height: 20px;
}

.medium-alude {
    font-weight: @alude-font-weight-base;
    font-size: 16px;
    line-height: 20px;
}

.medium-semibold-alude {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.small-alude {
    font-weight: @alude-font-weight-base;
    font-size: 12px;
    line-height: 20px;
}

.small-semibold-alude {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.super-small-alude {
    font-weight: @alude-font-weight-base;
    font-size: 8px;
    line-height: 10px;
}

.bold {
    font-weight: 700;
}

.normal {
    font-weight: normal;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

@media print {
    .print-hidden {
        display: none;
    }
}

.horizontal {
    &.reverse-wrap {
        flex-wrap: wrap-reverse;
    }
}

.vertical {
    &.reverse-wrap {
        flex-wrap: wrap-reverse;
    }
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-auto {
    overflow: auto;
}

#chat-widget-container {
    z-index: 1 !important;
}
