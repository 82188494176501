@import (reference) '../../style/global.less';

div.content {
    max-width: 400px;
    position: relative;
    flex: 0 0 auto !important;
}

.checklist {
    & svg {
        width: 24px !important;
    }
}

.video {
    position: relative;
    padding-bottom: 225px;
    height: 0;
    position: relative;
}

.video,
.image {
    & img {
        height: auto;
        display: block;
        width: 100%;
    }
}

@media all and (max-width: @tablet-max) {
    div.content {
        max-width: 100%;
    }

    .image {
        order: -1;
    }

    .video {
        order: -1;
        padding-bottom: 56.25%;
    }
}
