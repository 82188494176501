@import (reference) '../../style/global.less';

.title {
    & s,
    strong {
        color: @alude-red-orange;
    }
}

.labels {
    & svg {
        color: @alude-red-orange;
        width: 24px !important;
    }
}

.videoContainer {
    position: relative;
    border-radius: 8px;
    aspect-ratio: 16 / 9;
    &:extend(.elevation-element);
    z-index: 0;

    // plyr-container
    & > div {
        border-radius: 8px;
    }

    & video,
    & iframe {
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.placeholderContainer {
    display: flex;
    overflow-x: hidden;

    & > h1 {
        min-width: min(800px, 100%);

        & > span {
            vertical-align: middle;
        }
    }
}

.placeholder {
    visibility: hidden;
}

@media (max-width: @tablet-max) {
    .headlineReverse {
        flex-direction: column-reverse !important;
        margin-top: -16px;
    }
}

.subtitle {
    &:extend(h4);
}

@keyframes typing {
    0%,
    100% {
        width: 0%;
    }

    15%,
    95% {
        width: 100%;
    }
}

@keyframes caret {
    from,
    to {
        border-right: 1px 0 0 #0000;
    }
    50% {
        box-shadow: 1px 0 0 @alude-red-orange;
    }
}

@keyframes move {
    100% {
        transform: translateY(-500%);
    }
}

.animationContainer {
    color: @alude-red-orange;
    display: inline-block;

    & > span {
        display: grid;
        overflow: hidden;
        height: 1.2em;
    }

    & span span {
        width: 0%;
        max-width: max-content;
        overflow: hidden;
        height: inherit;
        word-break: break-all;
        animation: typing 5s linear infinite, move 25s steps(5) infinite,
            caret 0.5s step-end infinite;
    }

    & span span:before {
        content: ' ';
        display: inline-block;
    }
}
