@import (reference) '../../../../components/style/global.less';

.pathBar {
    border-bottom: 1px solid @alude-gray-4;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbContainer {
    flex: 1 !important;
}

.contentDivider {
    background-color: @alude-gray-4;
    width: 100%;
    height: 1px;
    border: none;
}

.menu {
    font-size: 24px;
    & img {
        width: 22px;
        height: 21.27px;
    }
}
