@import (reference) '../../style/global.less';

.container {
    width: 100%;
    & > .image {
        flex-basis: 100% !important;
        flex: 1;
        flex-grow: 1;
    }

    & > .content {
        flex: 1;
        flex-basis: 100% !important;
        flex-grow: 1;

        & > h1 {
            font-size: clamp(32px, 2.22vw, 40px);
            line-height: 120%;
        }
    }
}

.image {
    & img {
        width: 100%;
        border-radius: 24px;
    }
}
