@import (reference) '../../../components/style/global.less';

.container {
    border-bottom: 1px solid @alude-gray-4;
    width: 100%;
    background-color: #fff;
    font-size: 16px;
}

.submenu {
    width: 100%;
    max-width: 1504px;
    margin: 0 auto;
    display: flex;
    height: 64px;
    padding: 0 32px;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
}

.options {
    display: flex;
    gap: 24px;
}

.toggle {
    height: 64px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submenu a {
    color: @alude-gray-9;
    padding: 8px 0;
    line-height: 20px;
}

.submenu a:hover {
    text-decoration: underline;
}

.mobileContent {
    display: flex;
    flex-direction: column;
    padding: 0 16px 24px;
}

.mobileContent a {
    color: @alude-gray-9;
    padding: 8px 0;
    line-height: 20px;
}

.search {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
