@import (reference) '../../style/global.less';

.container {
    &:global(.vertical) {
        overflow: hidden;
    }

    &:hover {
        .imageContainer {
            transform: translateY(-216px);
        }

        .content {
            transform: translateY(-216px);
        }

        .description {
            opacity: 1;
            transition: opacity 0.4s ease-out;
            height: auto;
        }
    }
}

.content {
    transition: transform 0.4s ease;
    height: 236px;
    position: relative;
}

.description {
    transition: opacity 0.4s ease-in;
    opacity: 0;
    overflow: hidden;
}

.imageContainer {
    height: 200px;
    background-color: @alude-green-0;
    transition: transform 0.4s ease;
    position: relative;
}
