@import 'ButtonMixin';
@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/button/style/index.less';
@import (reference) '../style/ant-overrides.less';

@primary-color: @alude-orange-base;
@link-color: @alude-orange-base;
@btn-primary-bg: @prime-gradient-background;

@override-btn-mixins();

.button-variant-primary(@color; @background) {
    .button-color(@color; @background; transparent);

    text-shadow: @btn-text-shadow;
    box-shadow: @btn-primary-shadow;

    background-size: calc(100% + 2 * @btn-border-width);
    background-position: -@btn-border-width 0;

    &:hover,
    &:focus {
        & when not (@theme = dark) {
            .button-color(@color; ~'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), @{background}'; transparent);
        }
        background-size: calc(100% + 2 * @btn-border-width);
        background-position: -@btn-border-width 0;
    }

    &:active {
        & when not (@theme = dark) {
            .button-color(@color; ~'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), @{background}'; transparent);
        }
        background-size: calc(100% + 2 * @btn-border-width);
        background-position: -@btn-border-width 0;
    }
}

@btn-prefix-cls: ~'ald-btn-prime';

.ald-btn-prime {
    .ald-btn-prime();

    &-background-ghost&-primary {
        .button-variant-ghost(@primary-color);
    }
}
