@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/spin/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'ald';
@spin-prefix-cls: ~'ald-spin';

.@{spin-prefix-cls} {
    &-text {
        color: @alude-gray-7;
    }
}

// HACK: had to be replicated from ant stylesheet to here,
// otherwise the animation would not be defined.
@keyframes antSpinMove {
    to {
        opacity: 1;
    }
}

@keyframes antRotate {
    to {
        transform: rotate(405deg);
    }
}

.ald-spin {
    .ald-spin();
}
