@import (reference) '../../style/global.less';

.label {
    border-radius: 16px;
    color: @alude-gray-7;
    align-self: flex-start;
    padding: 2px 14px 2px 18px;
    font-size: 0.8em;
    font-weight: 500;
    border: 1px solid @alude-gray-4;
}
