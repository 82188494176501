@override-btn-mixins: {
    .btn-text() {
        &:hover,
        &:focus {
            box-shadow: @btn-shadow;
        }
    }

    .button-variant-other(@color; @background; @border) {
        &:hover,
        &:focus {
            .button-color(
                ~`colorPalette('@{btn-primary-bg}', 5) `;
                @background;
                @btn-default-border
            );
        }

        &-background-ghost:hover,
        &-background-ghost:focus {
            .button-color(
                ~`colorPalette('@{btn-primary-bg}', 5) `;
                @background;
                ~`colorPalette('@{btn-primary-bg}', 5) `
            );
        }

        &:active {
            .button-color(
                ~`colorPalette('@{btn-primary-bg}', 7) `;
                @background;
                ~`colorPalette('@{btn-primary-bg}', 7) `
            );
        }
    }

    .@{btn-prefix-cls} {
        --antd-wave-shadow-color: @primary-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &&-loading,
        &-background-ghost {
            box-shadow: none;
        }

        & .@{iconfont-css-prefix} {
            line-height: 0;
        }

        &-link,
        &-link:hover {
            padding: 0;
            border: 0px;
        }

        &:not(&-lg) .@{iconfont-css-prefix} {
            font-size: @alude-btn-icon-size;
        }

        &-lg .@{iconfont-css-prefix} {
            font-size: @alude-btn-icon-size-lg;
        }

        & > &-loading-icon {
            display: flex;
        }
    }
}; // comment suffix to suppress prettier
