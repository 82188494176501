@import (reference) '../../../../components/style/global.less';

.topBar {
    background-color: @alude-minsk-9;
    color: @alude-gray-0;
    max-height: 60px;

    .logo {
        height: 20px;
        margin-top: 2px;

        & img {
            height: 18px;
        }
    }
}
