@import (reference) '../../style/global.less';

.container {
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
    grid-template-rows: auto;
}

@media all and (min-width: @tablet-max) {
    .media {
        height: clamp(480px, 33.33vw, 338px);
    }
}

@media all and (max-width: @tablet-max) {
    .container {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }

    .media {
        aspect-ratio: 1 / 1;
    }
}

.media {
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
        width: 75%;
        height: auto;
        filter: brightness(0) invert(1);
    }
}

.title {
    color: @alude-gray-8;
}

.caption {
    color: @alude-gray-7;
}

.subtitle {
    color: @alude-gray-7;
    text-transform: uppercase;
}

.testimonialContainer {
    background-color: @alude-orange-1;
}

.statement * {
    &:extend(.semilarge-alude);
    font-style: italic;
    color: @alude-gray-9;
}
