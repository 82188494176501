@import (reference) '../../../../components/style/global.less';

.leftMenu {
    max-height: 100vh;
    min-height: calc(100vh - 113px);
}

.leftMenu > div {
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid @alude-gray-4;
}

.leftMenu > div > div {
    height: 100%;
}

.expandIcon {
    width: 12px;
    height: 12px;
}

.menuContainer {
    min-width: 256px;
}

.menuItem {
    cursor: pointer;

    &:hover {
        background-color: @alude-gray-2;
    }
}

.menuItemSelected {
    background-color: @alude-minsk-1;
}
